// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-ces-mrt-js": () => import("./../src/pages/CES-MRT.js" /* webpackChunkName: "component---src-pages-ces-mrt-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-environmental-compliance-js": () => import("./../src/pages/environmental-compliance.js" /* webpackChunkName: "component---src-pages-environmental-compliance-js" */),
  "component---src-pages-ert-fire-js": () => import("./../src/pages/ERT-Fire.js" /* webpackChunkName: "component---src-pages-ert-fire-js" */),
  "component---src-pages-ert-mine-js": () => import("./../src/pages/ERT-Mine.js" /* webpackChunkName: "component---src-pages-ert-mine-js" */),
  "component---src-pages-first-nations-jv-js": () => import("./../src/pages/first-nations-jv.js" /* webpackChunkName: "component---src-pages-first-nations-jv-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-health-safety-compliance-js": () => import("./../src/pages/health-safety-compliance.js" /* webpackChunkName: "component---src-pages-health-safety-compliance-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iso-osha-js": () => import("./../src/pages/ISO-OSHA.js" /* webpackChunkName: "component---src-pages-iso-osha-js" */),
  "component---src-pages-remote-site-services-js": () => import("./../src/pages/remote-site-services.js" /* webpackChunkName: "component---src-pages-remote-site-services-js" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

