import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import imgL1Logo from "../../assets/image/png/logo-transparent.png";
import imgL1LogoWhite from "../../assets/image/png/logo-transparent.png";

const StyledLogo = styled.img`
  height: 45px;
  width: auto;
`;
const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <StyledLogo src={imgL1LogoWhite} alt="" />
      ) : (
        <StyledLogo src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

export default Logo;
