import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import { Link } from "gatsby";

import { constantMenuItems } from "../Header/menuItems";

const TitleStyled = styled(Title)`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;
const UlStyledService = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;
const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true, data }) => {
  const industryLinks = data.contentfulIndustryLinks.industryLinkElements;
  const serviceLinksInternal = constantMenuItems[1].items
  //const contactLinksInternal = constantMenuItems[2].items

  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="12" md="12" className="mt-lg-0 mx-4 mx-lg-0">
                <Row>
                  <Col xs="12" md="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Industry Links
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        { industryLinks.map((links, i) => {
                          return <li key={"footer-industry-" + i}>
                                    <a href={links.linkSlug} target="_blank" rel="noopener noreferrer">
                                      {links.shortName}
                                    </a>
                                </li>
                            })
                        }
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="12" md="9">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Services
                      </TitleStyled>
                      <UlStyledService color={isDark ? "lightShade" : "darkShade"}>
                        { serviceLinksInternal.map((links, i) => {
                          return <li key={"footer-services-" + i}>
                            <Link to={'/'+ links.name}>
                              {links.label}
                            </Link>
                          </li>
                        })
                        }
                      </UlStyledService>
                    </div>
                  </Col>
                  {/* <Col xs="12" md="12" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Contact
                      </TitleStyled>
                       <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        { contactLinksInternal.map((links, i) => {
                          return <li key={"footer-contact-" + i}>
                            <Link to={'/'+ links.name} state={links.params}>
                              {links.label}
                            </Link>
                          </li>
                        })
                        }
                      </UlStyled>
                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; 2020 All Rights Reserved</p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li key="footer-social-1">
                    <a href="/">
                      <i className="icon icon-logo-twitter" />
                    </a>
                  </li>
                  <li key="footer-social-2">
                    <a href="/">
                      <i className="icon icon-logo-linkedin" />
                    </a>
                  </li>
                  <li key="footer-social-3">
                    <a href="/">
                      <i className="icon icon-logo-facebook" />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
