
export const constantMenuItems = [
  {
    name: "",
    label: "Home",
  },
  {
    name: "services",
    label: "Services",
    items: [
      { name: "training", label: "Training", params: {} },
      { name: "CES-MRT", label: "Complete Emergency Services and Mine Rescue Training", params: {} },
      { name: "health-safety-compliance", label: "Health Safety Compliance", params: {} },
      { name: "environmental-compliance", label: "Environmental Compliance", params: {} },
      { name: "ISO-OSHA", label: "ISO & OSHA Compliance", params: {} },
      { name: "ERT-Fire", label: "ERT - Fire", params: {}},
      { name: "ERT-Mine", label: "ERT - Mine", params: {}},
      { name: "remote-site-services", label: "Remote Site Services", params: {}},
      { name: "first-nations-jv", label: "First Nations and JV Experience", params: {}},
    ],
  },
    {
        name: "gallery",
        label: "Gallery",
    },
]
export const constantMenuItemsMobile = [
  {
    name: "",
    label: "Home",
  },
  {
    name: "services",
    label: "Services",
    items: [
      { name: "training", label: "Training", params: {} },
      { name: "CES-MRT", label: "Complete Emergency Services and Mine Rescue Training", params: {} },
      { name: "health-safety-compliance", label: "Health Safety Compliance", params: {} },
      { name: "environmental-compliance", label: "Environmental Compliance", params: {} },
      { name: "ISO-OSHA", label: "ISO & OSHA Compliance", params: {} },
      { name: "ERT-Fire", label: "ERT - Fire", params: {}},
      { name: "ERT-Mine", label: "ERT - Mine", params: {}},
      { name: "remote-site-services", label: "Remote Site Services", params: {}},
      { name: "first-nations-jv", label: "First Nations and JV Experience", params: {}},
    ],
  },
  {
    name: "contact",
    label: "Contact Us",
  },
  {
    name: "gallery",
    label: "Gallery",
  },
];

